import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const appsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: ['/apps/chat'],
        component: React.lazy(() => import('./ChatBot')),
      },
      {
        path: ['/apps/courses-management/:id', '/apps/courses-management'],
        component: React.lazy(() => import('./CoursesManagement')),
      },
    ],
  },
];
