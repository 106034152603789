import {MessageType} from '@crema/services/db/apps/chat/connectionList';
import {getFileSize} from '@crema/utility/Utils';
import {Box, Button} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React from 'react';
import MathJax from 'react-mathjax';
import {Link} from 'react-router-dom';
// import {Redirect} from 'react-router-dom';
import {
  ConnectionObj,
  MessageDataObj,
  MessageObj,
} from 'types/models/apps/Chat';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import {
  onDisappearMessage,
  onSendSuggestedQuestion,
  onSkipSuggestedQuestion,
} from 'redux/actions';
import {AppState} from 'redux/store';

const myObj = {
  [MessageType.MEDIA]: (item: MessageDataObj, classes?: any) => (
    <Box className={classes.mediaWrapper}>
      {item.media!.mime_type.startsWith('video') ? (
        <Box display='flex'>
          <video src={item.media!.url} />
          <PlayCircleOutlineIcon className={classes.videoIcon} />
        </Box>
      ) : (
        <img alt='' src={item.media!.url} />
      )}
    </Box>
  ),
  [MessageType.DOC]: (item: MessageDataObj, classes?: any) => (
    <Box display='flex' flexWrap='nowrap'>
      <img alt='' src={'/assets/images/icon-docs-dark.svg'} />
      <Box component='span' display='inline-block' ml={2}>
        <Box>{item.media!.file_name}</Box>
        <Box>{getFileSize(item.media!.file_size)}</Box>
      </Box>
    </Box>
  ),
  [MessageType.TEXT]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      {item.message}
    </Box>
  ),
  [MessageType.DISAPPEAR]: (item: MessageDataObj, classes?: any) => (
    <Box component='p' mb={1} ml={3}>
      {item.message}
    </Box>
  ),
  [MessageType.CLICKABLE]: (
    item: MessageDataObj,
    dispatch: any,
    selectedUser: ConnectionObj,
    connectionList: ConnectionObj[],
    userMessages: MessageObj,
    classes?: any,
  ) => (
    <>
      <Box className={classes.clickableBox}>
        <Box className={classes.suggestedQue}>Suggested /Related Question:</Box>
        {item.message}
      </Box>
      <Box px={8} py={4} component='span' m={1} className={classes.spreadBox}>
        <Button
          onClick={() => {
            dispatch(
              onSendSuggestedQuestion(
                selectedUser.channelId,
                {
                  message: item.message,
                  message_type: MessageType.TEXT,
                  sender: localStorage.getItem('id') + '',
                  time: '',
                },
                connectionList,
              ),
            );
            // dispatch(
            //   onDisappearMessage(
            //     selectedUser.channelId,
            //     connectionList,
            //     userMessages,
            //     item.id,
            //   ),
            // );
          }}>
          <CheckIcon className={classes.checkButton} />
        </Button>
        <Button
          onClick={() => {
            dispatch(
              onSkipSuggestedQuestion(selectedUser.channelId, {
                message: item.message,
                message_type: MessageType.TEXT,
                sender: localStorage.getItem('id') + '',
                time: '',
              }),
            );
            dispatch(
              onDisappearMessage(
                selectedUser.channelId,
                connectionList,
                userMessages,
                item.id,
              ),
            );
          }}>
          <CloseIcon className={classes.closeButton} />
        </Button>
      </Box>
    </>
  ),
  [MessageType.LINK]: (item: MessageDataObj, classes?: any) => (
    <Box className={classes.root} component='p' mb={1} ml={3}>
      <Link
        to={item?.url || ''}
        color='inherit'
        style={{textDecoration: 'none', color: 'black'}}>
        {item.message}
      </Link>
    </Box>
  ),
  [MessageType.EQUATION]: (item: MessageDataObj, classes?: any) => (
    <Box>
      <MathJax.Provider>
        <div>
          {/* This is an inline math formula:{' '}
          <MathJax.Node
            inline
            formula={`f(x) = \\int{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi`}
          /> */}
          <MathJax.Node formula={item.message || ''} />
        </div>
      </MathJax.Provider>
    </Box>
  ),
};
export const GetMessage = (item: MessageDataObj, classes: any) => {
  const dispatch = useDispatch();
  const {selectedUser}: {selectedUser: ConnectionObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  const {
    connectionList,
    userMessages,
  }: {connectionList: ConnectionObj[]; userMessages: MessageObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  const jsx = myObj[item.message_type](
    item,
    dispatch,
    selectedUser,
    connectionList,
    userMessages,
    classes,
  );
  return jsx;
};
