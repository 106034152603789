import React, {useContext, useEffect} from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import {ContentView} from '@crema/index';
import ChatBot from '../../ChatBot';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '@crema/utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import {LayoutType} from 'shared/constants/AppEnums';
import AppContextPropsType from 'types/AppContextPropsType';
import {useDispatch, useSelector} from 'react-redux';
import {onGetConnectionList} from 'redux/actions';
import {AppState} from 'redux/store';
import {ConnectionObj} from 'types/models/apps/Chat';

interface StandardLayoutProps {}

const StandardLayout: React.FC<StandardLayoutProps> = () => {
  const {footer, themeStyle, layoutType, footerType} =
    useContext<AppContextPropsType>(AppContext);
  const classes = useStyles({footer, themeStyle});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetConnectionList());
  }, [dispatch]);

  const {connectionList}: {connectionList: ConnectionObj[]} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppSidebar />

      <Box className={classes.mainContent}>
        <Hidden mdDown>
          <Box className={classes.mainContainer}>
            <AppHeader />
            <ContentView />
            <AppFixedFooter />
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box className={classes.mainContainerFull}>
            <AppHeader />
            <ContentView />
            <AppFixedFooter />
          </Box>
        </Hidden>
      </Box>
      {/* <ThemeSetting /> */}
      <ChatBot connectionList={connectionList} />
    </Box>
  );
};

export default StandardLayout;
