import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

import {
  CreateCourseDTO,
  CreateCourseReponse,
  CreateLessonDTO,
  CreateLessonReponse,
  DeleteCourseResponse,
  EnrolCourseReponse,
  GetCompletionIncreaseRateResponse,
  GetCompletionRateResponse,
  GetCourseLessonsReponse,
  GetTestQuestionsResponse,
  GetCourseReponse,
  GetCoursesResponse,
  SendAnswersDTO,
  SendAnswersResponse,
  GetCourseTestsResponse,
  GetCourseStatusReponse,
  CreateTestResponse,
  CreateTestDTO,
  CreateQuestionDTO,
  CreateQuestionResponse,
  UnenrolCourseReponse,
  GetEventResponse,
  GetEventLessonsResponse,
  CreateEventDTO,
  CreateEventResponse,
  GetCourseEventsResponse,
  UpdateCourseResponse,
  GetTestResultsResponse,
} from '../../../../types/models/apps/Course';

export function getProfCourses(): Promise<GetCoursesResponse> {
  return jwtAxios.get('/myCourses/');
}

export function getAllCourses(): Promise<GetCoursesResponse> {
  return jwtAxios.get('/courses/');
}

export function createCourse(
  createCourseDTO: CreateCourseDTO,
): Promise<CreateCourseReponse> {
  return jwtAxios.post('/courses/', createCourseDTO);
}

export function createLesson(
  createLessonDTO: CreateLessonDTO,
  eventId: string,
): Promise<CreateLessonReponse> {
  return jwtAxios.post(`/events/${eventId}/lessons`, createLessonDTO);
}

export function getCourseLessons(
  courseId: number,
): Promise<GetCourseLessonsReponse> {
  return jwtAxios.get(`/courses/${courseId}/lessons/`);
}

export function getCourseById(courseId: number): Promise<GetCourseReponse> {
  return jwtAxios.get(`/courses/${courseId}`);
}

export function enrolCourse(c_id: number): Promise<EnrolCourseReponse> {
  return jwtAxios.post(`/courses/${c_id}/enroll`);
}

export function getCourseStatus(c_id: number): Promise<GetCourseStatusReponse> {
  return jwtAxios.get(`/courses/${c_id}/enroll_status`);
}

export function sendAnswers(
  answers: SendAnswersDTO,
  t_id: number,
): Promise<SendAnswersResponse> {
  return jwtAxios.post(`/tests/${t_id}/Results/`, answers);
}

export function getCompletionRate(
  c_id: string,
): Promise<GetCompletionRateResponse> {
  return jwtAxios.get(`/courses/${c_id}/completionRate`);
}

export function getCompletionIncreaseRate(
  c_id: string,
): Promise<GetCompletionIncreaseRateResponse> {
  return jwtAxios.get(`/courses/${c_id}/competencyIncreaseChart`);
}

export function deleteCourse(courseId: number): Promise<DeleteCourseResponse> {
  return jwtAxios.delete(`/courses/${courseId}`);
}

export function getTestQuestions(
  testId: number,
): Promise<GetTestQuestionsResponse> {
  return jwtAxios.get(`/tests/${testId}/questions`);
}

export function getCourseTests(
  courseId: number,
): Promise<GetCourseTestsResponse> {
  return jwtAxios.get(`/courses/${courseId}/tests`);
}

export function createTest(
  courseId: number,
  createTestDTO: CreateTestDTO,
): Promise<CreateTestResponse> {
  return jwtAxios.post(`/courses/${courseId}/tests`, createTestDTO);
}

export function createQuestion(
  testId: number,
  createQuestionDTO: CreateQuestionDTO,
): Promise<CreateQuestionResponse> {
  return jwtAxios.post(`/tests/${testId}/questions`, createQuestionDTO);
}

export function unenrolCourse(courseId: number): Promise<UnenrolCourseReponse> {
  return jwtAxios.post(`/courses/${courseId}/unenroll`);
}

export function getEventById(eventId: number): Promise<GetEventResponse> {
  return jwtAxios.get(`/courses/events/${eventId}`);
}

export function getCourseEvents(
  courseId: number,
): Promise<GetCourseEventsResponse> {
  return jwtAxios.get(`/courses/${courseId}/events`);
}

export function getEventLessons(
  eventId: number,
): Promise<GetEventLessonsResponse> {
  return jwtAxios.get(`/events/${eventId}/lessons`);
}

export function createEvent(
  courseId: number,
  createEventDTO: CreateEventDTO,
): Promise<CreateEventResponse> {
  return jwtAxios.post(`/courses/${courseId}/events`, createEventDTO);
}

export function deleteEvent(eventId: number): Promise<DeleteCourseResponse> {
  return jwtAxios.delete(`/courses/events/${eventId}`);
}

export function updateCourse(
  courseId: number,
  newCourse: CreateCourseDTO,
): Promise<UpdateCourseResponse> {
  return jwtAxios.put(`/courses/${courseId}`, newCourse);
}

export function getTestResults(
  testId: number,
): Promise<GetTestResultsResponse> {
  return jwtAxios.get(`/tests/${testId}/Results/`);
}
