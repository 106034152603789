import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const menuLevelConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/courses/course-details/:id',
        component: React.lazy(
          () => import('./MechanicalPageComponents/CourseContent'),
        ),
      },
      {
        path: '/courses/course-detail/:courseId/learning-event/:eventId',
        component: React.lazy(
          () => import('./MechanicalPageComponents/EventDetail'),
        ),
      },
      {
        path: '/courses/course-detail/:courseId/test/:testId',
        component: React.lazy(() => import('./CourseQuestions/index')),
      },
      {
        path: '/courses/:labelId',
        component: React.lazy(() => import('../courses/index')),
      },

      {
        path: '/course-3',
        component: React.lazy(() => import('./MenuLevel')),
      },
      {
        path: '/error-pages/coming-soon',
        component: React.lazy(() => import('../errorPages/ComingSoon')),
      },
    ],
  },
];
