export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'Welcome',
    title: 'Welcome',
    messageId: 'sidebar.pages.extraPages.welcome',
    type: 'item',
    icon: 'work_outline',
    url: '/welcome',
  },
  {
    id: 'chat',
    title: 'Chat',
    messageId: 'sidebar.apps.chat',
    type: 'item',
    icon: 'sidebar.apps.chat',
    url: '/apps/chat',
  },
  {
    id: 'multi-level',
    title: 'Multi Level',
    messageId: 'sidebar.topicList',
    type: 'collapse',
    icon: 'menu',
    children: [
      {
        id: 'course-1-1',
        title: 'Course 1',
        messageId: 'sidebar.topicList.topic1',
        type: 'item',
        url: '/courses/1',
        // children: [
        //   {
        //     id: 'Geometry Construction',
        //     title: 'Geometry Construction',
        //     messageId: 'Geometry Construction',
        //     type: 'item',
        //     url: '/course-1',
        //   },
        // ],
      },
      {
        id: 'course-1-2',
        title: 'Course 2',
        messageId: 'sidebar.topicList.topic2',
        type: 'item',
        url: '/courses/2',
        // children: [
        //   {
        //     id: 'Geometry Construction',
        //     title: 'Geometry Construction',
        //     messageId: 'Geometry Construction',
        //     type: 'item',
        //     url: '/course-2',
        //   },
        // ],
      },
    ],
  },
  {
    id: 'courses-management',
    title: 'Courses Management',
    messageId: 'sidebar.coursesManagement',
    type: 'item',
    icon: 'auto_stories_icon',
    url: '/apps/courses-management',
    auth: ['professor'],
  },
];
export default routesConfig;
