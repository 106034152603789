import clsx from 'clsx';
import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {Button} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import {AuthUser} from 'types/models/AuthUser';
import {MessageDataObj} from 'types/models/apps/Chat';

import {getFileSize} from '@crema/utility/Utils';
import IntlMessages from '@crema/utility/IntlMessages';
import {MessageType} from '@crema/services/db/apps/chat/connectionList';

import useStyles from './MessageItem.style';
import {Link} from 'react-router-dom';

const getMessage = (item: MessageDataObj, classes: any) => {
  if (item.message_type === MessageType.TEXT) {
    return (
      <Box component='p' mb={1} ml={3}>
        {item.message}
      </Box>
    );
  } else if (item.message_type === MessageType.CLICKABLE) {
    return (
      <Box>
        <Button
          className={classes.ClickableMessageButton}
          variant='contained'
          color='primary'
          disableElevation>
          {item.message}
        </Button>
      </Box>
    );
  } else if (item.message_type === MessageType.DOC && item.media) {
    return (
      <Box display='flex' flexWrap='nowrap'>
        <Box
          component='span'
          className='pointer'
          display='flex'
          flexWrap='nowrap'>
          {/* <img alt='' src={'/assets/images/icon-docs.svg'} /> */}
          <Box component='span' display='inline-block' mr={2}>
            <Box>{item.media.file_name}</Box>
            <Box>{getFileSize(item.media.file_size)}</Box>
          </Box>
        </Box>
      </Box>
    );
  } else if (item.message_type === MessageType.LINK) {
    return (
      <Box component='p' mb={1} ml={3}>
        {/* <a href={item?.url}>  {item.message} </a> */}
        <Link to={item?.url || ''} color='inherit'>
          {item.message}
        </Link>
      </Box>
    );
  } else if (item.media) {
    return (
      <Box className={classes.mediaWrapper}>
        {item.media.mime_type.startsWith('video') ? (
          <Box display='flex'>
            <video src={item.media.url} />
            <PlayCircleOutlineIcon className={classes.videoIcon} />
          </Box>
        ) : (
          <img alt='' src={item.media.url} />
        )}
      </Box>
    );
  }
};

interface SenderMessageItemProps {
  item: MessageDataObj;
  authUser: AuthUser;
  onClickClickableMessage: (item: MessageDataObj) => void;
  onClickEditMessage: (item: MessageDataObj) => void;
  deleteMessage: (id: any) => void;
}

const SenderMessageItem: React.FC<SenderMessageItemProps> = ({
  authUser,
  item,
  onClickClickableMessage,
  onClickEditMessage,
  deleteMessage,
}) => {
  const [isMoreIcon, onOpenMoreIcon] = useState(null);

  const onViewMoreOpen = (event: any) => {
    onOpenMoreIcon(event.currentTarget);
  };

  const onViewMoreClose = () => {
    onOpenMoreIcon(null);
  };

  const clickableMessage = (message: string) => {
    onClickClickableMessage({
      message,
      message_type: MessageType.TEXT,
      sender: localStorage.getItem('id') + '',
      time: '',
    });
  };
  const getUserAvatar = () => {
    const name = authUser.displayName;
    if (name) {
      return name.charAt(0).toUpperCase();
    }
    if (authUser.email) {
      return authUser.email.charAt(0).toUpperCase();
    }
  };

  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.messageItemRoot, 'right')}
      display='flex'
      justifyContent={'flex-end'}>
      <Box className={classes.messageChatRoot}>
        <Box
          className={clsx(classes.messageTime, 'message-time')}
          component='span'>
          {item.time}
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          {item.message_type === MessageType.TEXT && item.media && (
            <Box className='pointer' component='span' mt='auto'>
              <a href={item.media!.url} download={item.media!.file_name}>
                <img alt='' src={'/assets/images/icon-download.svg'} />
              </a>
            </Box>
          )}
          {item.message_type === MessageType.CLICKABLE ? (
            <Box
              onClick={() => {
                clickableMessage(item.message || '');
              }}
              className={classes.ClickableMessageChat}
              ml='auto'>
              <Box className='message-info'>{getMessage(item, classes)}</Box>
            </Box>
          ) : (
            <Box className={classes.messageChat} ml='auto'>
              <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>

              <Box className='message-info'>
                {getMessage(item, classes)}

                {item.edited && (
                  <Box className={classes.editRoot}>
                    <EditIcon />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {item.message_type === MessageType.CLICKABLE ? null : (
        <Box className={classes.arrowIcon}>
          <Box
            ml={2}
            className={classes.textPointer}
            component='span'
            color='text.disabled'>
            <MoreVertIcon onClick={onViewMoreOpen} />
          </Box>

          <Menu
            anchorEl={isMoreIcon}
            open={Boolean(isMoreIcon)}
            onClose={onViewMoreClose}>
            {item.message_type === MessageType.TEXT ? (
              <MenuItem
                onClick={() => {
                  onViewMoreClose();
                  onClickEditMessage(item);
                }}>
                <IntlMessages id='common.edit' />
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => {
                onViewMoreClose();
                deleteMessage(item.id);
              }}>
              <IntlMessages id='common.delete' />
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default SenderMessageItem;
