import axios from 'axios';
axios.defaults.withCredentials = true;

const jwtAxios = axios.create({
  // baseURL: 'http://localhost:5000/api/', //YOUR_API_URL HERE
  baseURL: 'http://85.199.212.53:8000/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token: string | null) => {
  if (token) {
    // jwtAxios.defaults.headers.common['x-auth-token'] = token;
    jwtAxios.defaults.headers.common['Authorization'] = `Token ${token}`;
    localStorage.setItem('token', token);
  } else {
    // delete jwtAxios.defaults.headers.common['x-auth-token'];
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('ChatId');
  }
};

export default jwtAxios;
