import {Response} from '../Common';

export interface LabelObj {
  id: number;
  name: string;
  color: string;
}

export enum QuestionType {
  NUMERAL = 0,
  TEXT = 1,
}

export interface QuestionObj {
  id: number;
  content: string;
  answer_type: QuestionType;
  answer_value: number;
}

export enum TestType {
  PRE = 0,
  POST = 1,
}

export interface TestObj {
  id: number;
  name: string;
  summary: string;
  questions: QuestionObj[];
  type?: TestType;
}

export interface CourseObj {
  id: number;
  title: string;
  label: LabelObj[];
  createdOn?: string;
  isDeleted?: boolean;
  summary: string;
  events: EventObj[];
  completionRate?: number;
  completionRateIncrease?: CompletionRateIncrease;
  tests: TestObj[];
}

export interface GetCoursesDTO {
  id: number;
  c_title: string;
  summary: string;
  category: string;
  author: number[];
  preview_name: string;
}

export interface GetCoursesResponse
  extends Response<{
    courses: GetCoursesDTO[];
  }> {}

export interface CreateCourseReponse
  extends Response<{course: GetCoursesDTO}> {}

export interface CreateCourseDTO {
  c_title: string;
  summary: string;
  category: string;
}

export interface CreateLessonReponse extends Response<{id: number}> {}

export interface CreateLessonDTO {
  l_title: string;
  preview_name: string;
  content: string;
}

export interface LessonObj {
  id: number;
  l_title: string;
  content: string;
  video_link?: string;
}

export interface GetCourseLessonsReponse
  extends Response<{lessons: LessonObj[]}> {}

export interface GetCourseDetailDTO {
  id: number;
  c_title: string;
  summary: string;
  category: string;
  author: number[];
  preview_name: string;
  events: EventObj[];
  tests: GetTestDTO[];
}

export interface EventObj {
  id: number;
  name: string;
  desc: string;
  lessons: LessonObj[];
}

export interface GetCourseReponse
  extends Response<{course: GetCourseDetailDTO}> {}

export interface GetLessonDTO {
  id: number;
  l_title: string;
  content: string;
}

export interface EnrolCourseReponse extends Response<{status: string}> {}

export interface GetCourseStatusReponse extends Response<{status: number}> {}

export interface SendAnswersResponse extends Response<{status: boolean}> {}

export interface SendAnswersDTO {
  q_ids: number[];
  values: string[];
}

export interface GetCompletionRateResponse
  extends Response<{comp_rate: number}> {}

export interface CompletionRateIncrease {
  students_num: number;
  cir_statistics: any[];
}

export interface GetCompletionIncreaseRateResponse
  extends Response<{cir: CompletionRateIncrease}> {}

export interface DeleteCourseResponse extends Response<{res: boolean}> {}

export interface UpdateCourseResponse
  extends Response<{course: CreateCourseDTO}> {}

export interface GetQuestionDTO {
  id: number;
  content: string;
  answer_type: number;
  test: number;
}

export interface GetTestQuestionsResponse
  extends Response<{questions: GetQuestionDTO[]}> {}

export interface GetTestDTO {
  id: number;
  name: string;
  summary: string;
  course: number;
  is_preTest: boolean;
  is_postTest: boolean;
  questions?: QuestionObj[];
}

export interface GetCourseTestsResponse
  extends Response<{tests: GetTestDTO[]}> {}

export interface CreateTestResponse extends Response<{test: GetTestDTO}> {}

export interface CreateTestDTO {
  name: string;
  summary: string;
  is_preTest?: number;
  is_postTest?: number;
}

export interface CreateQuestionDTO {
  content: string;
  answer_type: number;
}

export interface CreateQuestionResponse
  extends Response<{questions: GetQuestionDTO}> {}

export interface UnenrolCourseReponse extends Response<{status: string}> {}

export interface GetEventResponse extends Response<{event: EventObj}> {}

export interface GetCourseEventsResponse
  extends Response<{events: EventObj[]}> {}

export interface GetEventLessonsResponse
  extends Response<{lessons: LessonObj[]}> {}

export interface CreateEventDTO {
  name: string;
  desc: string;
}

export interface CreateEventResponse extends Response<{event: EventObj}> {}

export interface UpdatedCourseObj {
  id: number;
  title: string;
  summary: string;
  label: LabelObj[];
}

export interface GetTestResultsDTO {
  questions: number;
  answer: string;
}

export interface GetTestResultsResponse
  extends Response<{results: GetTestResultsDTO[]}> {}
