import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import {useDispatch, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {onDeleteMessage, onReceiveMessage, onSendMessage} from 'redux/actions';
import AddNewMessage from './AddNewMessage';
import MessagesList from './MessagesList';
import {useAuthUser} from '@crema/utility/AppHooks';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import IntlMessages from '@crema/utility/IntlMessages';
import grey from '@material-ui/core/colors/grey';
import {useAppsContentStyles} from '@crema/core/AppsContainer/AppsContent';
import AppsFooter from '@crema/core/AppsContainer/AppsFooter';
import {MessageType} from '@crema/services/db/apps/chat/connectionList';
import {
  ConnectionObj,
  MessageDataObj,
  MessageObj,
} from 'types/models/apps/Chat';
import {AppState} from 'redux/store';
import WebSocketInstance from '@crema/services/websocket';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  scrollChatNomain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100% !important',
  },
  noMessage: {
    fontSize: 18,
    color: grey[500],
  },
}));

interface MessagesScreenProps {
  selectedUser: ConnectionObj;
}

const MessagesScreen: React.FC<MessagesScreenProps> = ({selectedUser}) => {
  const [message, setMessage] = useState<string | undefined>('');
  const [, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const user = useAuthUser();
  let _scrollBarRef: any = null;
  let history = useHistory();
  const {userMessages}: {userMessages: MessageObj} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);

  const {connectionList}: {connectionList: ConnectionObj[]} = useSelector<
    AppState,
    AppState['chatApp']
  >(({chatApp}) => chatApp);
  useEffect(() => {
    const messagesFunc = (messages: any) => {
      messages.forEach((message: any) => {
        const response = {
          message: message.content,
          message_type: MessageType.TEXT,
          sender: message.author,
          time: message.time,
        };

        dispatch(
          onReceiveMessage(
            selectedUser.channelId,
            response,
            connectionList,
            selectedUser,
          ),
        );
      });
    };
    const newMessageFunc = (message: any) => {
      const response = {
        id: message.id,
        message: message.content,
        message_type: MessageType.TEXT,
        sender: message.author,
        time: message.time,
      };

      dispatch(
        onReceiveMessage(
          message.author,
          response,
          connectionList,
          selectedUser,
        ),
      );
    };

    const courseInfoFunc = (message: any) => {
      const response = {
        message: message.content,
        message_type: MessageType.LINK,
        sender: message.author,
        time: message.time,
      };

      if (localStorage.getItem('id') != message.author)
        setTimeout(() => {
          dispatch(
            onReceiveMessage(
              selectedUser.channelId,
              response,
              connectionList,
              selectedUser,
            ),
          );
        }, 1500);
      else
        dispatch(
          onReceiveMessage(
            selectedUser.channelId,
            response,
            connectionList,
            selectedUser,
          ),
        );
    };

    WebSocketInstance.addCallbacks(
      messagesFunc,
      newMessageFunc,
      courseInfoFunc,
    );
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (
      userMessages &&
      userMessages.messageData &&
      userMessages.messageData.length > 0
    ) {
      if (_scrollBarRef) {
        // THAT IS RESPONSIPLE FOR SCROLL DOWN AUTOMATICALLY WHEN YOU SEND A NEW MESSAGE
        _scrollBarRef._container.scrollTop = 99999;
      }
    }
  }, [userMessages, _scrollBarRef]);

  const sendFileMessage = (fileMessage: MessageDataObj) => {
    // const data: MessageDataObj = {
    //   ...fileMessage,
    //   // time: moment().format('llll'),
    //   time: '',
    // };
    // dispatch(onSendMessage(selectedUser.channelId, data));
  };

  const onSend = (message: string) => {
    const id: string = localStorage.getItem('id') + '';
    if (message === 'visualize model' || message === 'view model') {
      history.push('/web-viewer');
    } else {
      const data: MessageDataObj = {
        message,
        message_type: MessageType.TEXT,
        sender: id,
        time: moment().format('llll'),
      };
      dispatch(onSendMessage(selectedUser.channelId, data, connectionList));
      setMessage('');
    }
  };

  const onClickClickableMessage = (data: MessageDataObj) => {
    if (data.message) onSend(data.message);
  };

  const onClickEditMessage = (data: MessageDataObj) => {
    if (data.message_type === MessageType.TEXT) {
      setIsEdit(true);
      setMessage(data.message);
    }
  };

  const deleteMessage = (messageId: number) => {
    dispatch(onDeleteMessage(selectedUser.channelId, messageId));
  };

  const appsContentStyles = useAppsContentStyles({
    isDetailView: false,
    fullView: false,
  });

  const classes = useStyles();
  return (
    <Box display='flex' height={1} flexDirection='column'>
      {userMessages && user ? (
        <PerfectScrollbar
          ref={(ref) => {
            _scrollBarRef = ref;
          }}
          className={appsContentStyles.appsContentContainer}>
          <MessagesList
            userMessages={userMessages}
            authUser={user}
            selectedUser={selectedUser}
            onClickClickableMessage={onClickClickableMessage}
            onClickEditMessage={onClickEditMessage}
            deleteMessage={deleteMessage}
          />
        </PerfectScrollbar>
      ) : (
        <Box
          className={clsx(classes.scrollChatNomain, 'scroll-chat-nomain')}
          component='span'>
          <Box component='span' className={classes.noMessage}>
            <IntlMessages id='chatApp.sayHi' /> {selectedUser.name}
          </Box>
        </Box>
      )}

      <AppsFooter mt='auto'>
        <AddNewMessage
          currentMessage={message}
          sendFileMessage={sendFileMessage}
          onSendMessage={onSend}
        />
      </AppsFooter>
    </Box>
  );
};

export default MessagesScreen;
