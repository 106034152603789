import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/Utils';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {
  ADD_NEW_MESSAGE,
  CHANGE_DIGITAL_ENG_WINDOW,
  DELETE_MESSAGE,
  DELETE_USER_MESSAGES,
  EDIT_MESSAGE,
  GET_CONNECTIONS_LIST,
  GET_USER_MESSAGES,
  SELECT_USER,
  SET_CHAT_ID,
  SET_LAST_MESSAGE,
} from '../../types/actions/Chat.actions';
import {
  ConnectionObj,
  MessageDataObj,
  MessageObj,
} from '../../types/models/apps/Chat';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import WebSocketInstance from '@crema/services/websocket';
import {MessageType} from '@crema/services/db/apps/chat/connectionList';
import {
  getConnectionList,
  getUserMessagesByChatId,
} from '@crema/services/apis/chat';
import moment from 'moment';

export const setChatId = (id: number) => ({
  type: SET_CHAT_ID,
  payload: id,
});

export const onGetConnectionList = () => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getConnectionList();
      if (status === 200) {
        dispatch(fetchSuccess());
        let connectionList: ConnectionObj[] = data.connections_list.map(
          (item) => ({
            id: item.id,
            channelId: item.chat_id,
            name: item.username,
            image: '',
            status: 'online',
            username: item.username,
            lastMessage: {
              id: 0,
              message: '',
              type: 'unread',
              time: '',
            },
          }),
        );
        dispatch({type: GET_CONNECTIONS_LIST, payload: connectionList});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetConnectionMessages = (channelId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getUserMessagesByChatId(channelId);
      if (status === 200) {
        const messages: MessageObj = {
          channelId: channelId,
          messageData: data.messages.map((msg) => ({
            id: msg.id,
            sender: msg.contact.user,
            message: msg.content,
            message_type: 3,
            time: msg.timestamp,
          })),
        };
        dispatch(fetchSuccess());
        dispatch({type: GET_USER_MESSAGES, payload: messages});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const onSendMessage = (
  channelId: number,
  message: MessageDataObj,
  connectionList: ConnectionObj[],
) => {
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const rep: MessageDataObj = {
      message: message.message,
      message_type: MessageType.TEXT,
      sender: localStorage.getItem('id') + '',
      time: moment().format('llll'),
    };
    const user: ConnectionObj = connectionList.find(
      (connection) => connection.channelId === channelId,
    )!;
    const userMessages: MessageObj = {channelId, messageData: [rep]};
    dispatch({type: ADD_NEW_MESSAGE, payload: {data: {user, userMessages}}});

    const socketData = {
      from: localStorage.getItem('id'),
      content: message.message,
      chatId: channelId,
    };
    if (message.message === '@admin') {
      console.log('admin ');
    } else if (
      message.message?.toLowerCase().includes('looking for help') ||
      message.message?.toLowerCase().includes('hi')
    ) {
      let help_message: string = 'Hi, How can I help you?';
      const rep: MessageDataObj = {
        message: help_message,
        message_type: MessageType.TEXT,
        sender: '2',
        time: moment().format('llll'),
      };
      const user: ConnectionObj = connectionList.find(
        (connection) => connection.channelId === channelId,
      )!;
      userMessages.messageData = [rep];
      dispatch({type: ADD_NEW_MESSAGE, payload: {data: {user, userMessages}}});
    } else if (
      message.message?.toLowerCase().includes('what are available courses')
    ) {
      let help_message: string =
        '1- Geometry Construction for Mechanical Analysis';
      const rep: MessageDataObj = {
        message: help_message,
        message_type: MessageType.TEXT,
        sender: '2',
        time: moment().format('llll'),
      };
      help_message = '2- Geometry Construction for Thermal Analysis';
      const rep2: MessageDataObj = {
        message: help_message,
        message_type: MessageType.TEXT,
        sender: '2',
        time: moment().format('llll'),
      };
      const user: ConnectionObj = connectionList.find(
        (connection) => connection.channelId === channelId,
      )!;
      userMessages.messageData = [rep, rep2];
      dispatch({type: ADD_NEW_MESSAGE, payload: {data: {user, userMessages}}});
    } else if (
      message.message?.toLowerCase().includes('can you simplify the geometry')
    ) {
      let help_message: string =
        'Sure, please download the following macros and run them in the environment of your choice.';

      const rep2: MessageDataObj = {
        message: help_message,
        message_type: MessageType.TEXT,
        sender: '2',
        time: moment().format('llll'),
      };

      const rep: MessageDataObj = {
        message: help_message,
        message_type: MessageType.DOC,
        media: {
          url: 'http://85.199.212.53:3000/macros.zip',
          mime_type: 'image',
          file_name: 'macros.zip',
          file_size: 244665,
        },
        time: moment().format('llll'),
        sender: '',
      };
      const user: ConnectionObj = connectionList.find(
        (connection) => connection.channelId === channelId,
      )!;
      userMessages.messageData = [rep, rep2];
      dispatch({type: ADD_NEW_MESSAGE, payload: {data: {user, userMessages}}});
    } else {
      WebSocketInstance.newChatMessage(socketData);
    }
  };
};

export const onSendSuggestedQuestion = (
  channelId: number,
  message: MessageDataObj,
  connectionList: ConnectionObj[],
) => {
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const rep: MessageDataObj = {
      message: message.message,
      message_type: MessageType.TEXT,
      sender: localStorage.getItem('id') + '',
      time: '',
    };
    const user: ConnectionObj = connectionList.find(
      (connection) => connection.channelId === channelId,
    )!;
    const userMessages: MessageObj = {channelId, messageData: [rep]};
    dispatch({type: ADD_NEW_MESSAGE, payload: {data: {user, userMessages}}});
    const socketData = {
      from: localStorage.getItem('id'),
      content: '[accepted], ' + message.message,
      chatId: channelId,
    };
    WebSocketInstance.newChatMessage(socketData);
  };
};

export const onSkipSuggestedQuestion = (
  channelId: number,
  message: MessageDataObj,
) => {
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const socketData = {
      from: localStorage.getItem('id'),
      content: '[skipped], ' + message.message,
      chatId: channelId,
    };
    WebSocketInstance.newChatMessage(socketData);
  };
};

export const onReceiveMessage = (
  id: number,
  message: MessageDataObj,
  connectionList: ConnectionObj[],
  selectedUser: ConnectionObj,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    const type = message.message?.startsWith('[clickable],')
      ? MessageType.CLICKABLE
      : MessageType.TEXT;
    const messageContent = message.message?.startsWith('[clickable],')
      ? message.message.replace('[clickable],', '')
      : message.message;
    const rep: MessageDataObj = {
      id: message.id,
      message: messageContent,
      message_type: type,
      sender: message.sender,
      time: message.time,
    };

    if (selectedUser.id === id) {
      const userMessages = {
        channelId: selectedUser.channelId,
        messageData: [rep],
      };
      dispatch({
        type: ADD_NEW_MESSAGE,
        payload: {data: {user: selectedUser, userMessages}},
      });

      const user: ConnectionObj = connectionList.find(
        (connection) => connection.id === id,
      )!;
      dispatch({type: SET_LAST_MESSAGE, payload: {user, message}});
    } else {
      const user: ConnectionObj = connectionList.find(
        (connection) => connection.id === id,
      )!;
      dispatch({type: SET_LAST_MESSAGE, payload: {user, message}});
    }
  };
};

export const onEditMessage = (channelId: number, message: MessageDataObj) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    Api.put('/api/chatApp/message', {channelId, message})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: EDIT_MESSAGE,
            payload: {data: data.data},
          });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDisappearMessage = (
  channelId: number,
  connectionList: ConnectionObj[],
  userMessages: MessageObj,
  messageId?: number,
) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    const user = connectionList.find(
      (connection) => connection.channelId === channelId,
    );
    userMessages.messageData = userMessages.messageData.filter(
      (item) =>
        item.id !== messageId && item.message_type !== MessageType.DISAPPEAR,
    );
    if (user) {
      dispatch(fetchSuccess());
      dispatch({type: DELETE_MESSAGE, payload: {user, userMessages}});
    } else {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteMessage = (channelId: number, messageId: number) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    Api.post('/api/chatApp/delete/message', {channelId, messageId})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: DELETE_MESSAGE, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteConversation = (channelId: number) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/chatApp/delete/user/messages', {channelId})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: DELETE_USER_MESSAGES, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onSelectUser = (user: ConnectionObj) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({type: SELECT_USER, payload: user});
  };
};

export const onCreateNewChatId = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res: any = await jwtAxios.get(
        '/api-chat/create/?username=' + localStorage.getItem('id'),
      );
      const ID: any = res.data['connections_list'][0]['chat_id'];
      console.log('Res ChatId:', res);
      localStorage.setItem('ChatId', ID);
    } catch (err: any) {
      console.log(err);
      dispatch(showMessage('Enter your credentials'));
      // dispatch(fetchError(JSON.stringify( err.response.data)));
    }
  };
};

export const onChangeDigitalEngWindow = (isOpened: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({type: CHANGE_DIGITAL_ENG_WINDOW, payload: isOpened});
  };
};

export const onGetDigitEng = (connectionList: ConnectionObj[]) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    const user = connectionList.find(
      (connection) => connection.name === 'digit',
    );
    if (user) {
      WebSocketInstance.connect(localStorage.getItem('id'));
      dispatch({type: SELECT_USER, payload: user});
    } else {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
    if (user) {
      try {
        const {data, status} = await getUserMessagesByChatId(user?.channelId);
        if (status === 200) {
          const messages: MessageObj = {
            channelId: user?.channelId,
            messageData: data.messages.map((msg) => ({
              id: msg.id,
              sender: msg.contact.user,
              message: msg.content,
              message_type: 3,
              time: msg.timestamp,
            })),
          };
          dispatch(fetchSuccess());
          dispatch({type: GET_USER_MESSAGES, payload: messages});
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      } catch (error: any) {
        dispatch(fetchError(error.message));
      }
    }
  };
};

export const onGetSelfAssement = (channelId: number) => {
  const socketData = {
    from: localStorage.getItem('id'),
    content: '',
    chatId: channelId,
  };
  return (dispatch: Dispatch<AppActions>) => {
    WebSocketInstance.newChatMessage(socketData);
  };
};

export const onGetCourseInfo = (channelId: number, courseCategory: string) => {
  const socketData = {
    from: localStorage.getItem('id'),
    courseCategory,
    chatId: channelId,
  };
  return (dispatch: Dispatch<AppActions>) => {
    WebSocketInstance.getCousreInfo(socketData);
  };
};
