import {
  GetConnectionListResponse,
  GetUserMessagesResponse,
} from 'types/models/apps/Chat';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export function getConnectionList(): Promise<GetConnectionListResponse> {
  return jwtAxios.get(`/api-chat/create/`);
}

export function getUserMessagesByChatId(
  chatId: number,
): Promise<GetUserMessagesResponse> {
  return jwtAxios.get(`/Messages/${chatId}`);
}
