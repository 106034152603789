import React, {useEffect, useState} from 'react';
import {Box, IconButton, makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import {useIntl} from 'react-intl';
import 'emoji-mart/css/emoji-mart.css';
import {CremaTheme} from 'types/AppContextPropsType';
import {MessageDataObj} from 'types/models/apps/Chat';

const useStyles = makeStyles((theme: CremaTheme) => ({
  btnRoot: {
    backgroundColor: theme.palette.grey[100],
    '& .MuiIconButton-label': {
      width: 24,
      height: 24,
      paddingLeft: 3,
    },
  },
  EmojiPicker: {
    position: 'absolute',
    zIndex: 2147483000,
    right: '20px',
    bottom: '100px',
    minHeight: '250px',
    maxHeight: '704px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
    background: 'white',
    opacity: 1,
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'width 350ms ease 0s, height ease 0s, max-height ease 0s',
  },
}));

interface AddNewMessageProps {
  sendFileMessage: (message: MessageDataObj) => void;
  onSendMessage: (message: string) => void;
  currentMessage: string | undefined;
}

const AddNewMessage: React.FC<AddNewMessageProps> = ({
  sendFileMessage,
  onSendMessage,
  currentMessage = '',
}) => {
  const [message, setMessage] = useState<string>(currentMessage);

  useEffect(() => {
    setMessage(currentMessage);
  }, [currentMessage]);

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickSendMessage();
    }
  };

  const onClickSendMessage = () => {
    if (message) {
      onSendMessage(message);
      setMessage('');
    }
  };

  const classes = useStyles();

  const {messages} = useIntl();

  return (
    <Box display='flex'>
      <TextField
        multiline
        style={{width: '100%'}}
        variant='outlined'
        placeholder={messages['chatApp.sendMessagePlaceholder'] as string}
        value={message}
        onChange={(event) => {
          if (event.target.value !== '\n') setMessage(event.target.value);
        }}
        onKeyPress={onKeyPress}
      />

      <Box ml={2} display='flex' flexDirection='row' alignItems='center'>
        <div onClick={onClickSendMessage}>
          <IconButton
            className={classes.btnRoot}
            style={{height: 40, width: 40, marginRight: 10}}>
            <SendIcon />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
};

export default AddNewMessage;
