import {appIntl} from '../../@crema/utility/Utils';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {
  createCourse,
  createLesson,
  enrolCourse,
  getCompletionRate,
  getCompletionIncreaseRate,
  getCourseById,
  getCourseLessons,
  getProfCourses,
  sendAnswers,
  deleteCourse,
  getTestQuestions,
  getCourseTests,
  createTest,
  createQuestion,
  unenrolCourse,
  getAllCourses,
  getEventLessons,
  createEvent,
  getCourseEvents,
  deleteEvent,
  updateCourse,
  getTestResults,
} from '@crema/services/apis/course';
import {
  CourseObj,
  CreateCourseDTO,
  CreateEventDTO,
  CreateLessonDTO,
  QuestionObj,
  SendAnswersDTO,
  TestObj,
  TestType,
  UpdatedCourseObj,
} from 'types/models/apps/Course';
import {
  CREATE_NEW_COURSE,
  DELETE_COURSE,
  GET_COURSE_COMPLETION_RATE,
  GET_COURSE_COMPLETION_RATE_INCREASE,
  GET_COURSE_DETAIL,
  GET_COURSE_EVENTS,
  GET_COURSE_LESSONS_LIST,
  GET_COURSE_LIST,
  GET_COURSE_TESTS,
  GET_EVENT_LESSONS,
  GET_TEST_QUESTIONS,
  GET_TEST_RESULTS,
  UPDATE_COURSE_DETAIL,
} from 'types/actions/Course.action';
import {blue, green} from '@material-ui/core/colors';
import {useHistory} from 'react-router-dom';

const MeLabel = {
  id: 1,
  name: 'Mechanical Engineering',
  color: green[500],
};

const ThLabel = {
  id: 2,
  name: 'Thermal Engineering',
  color: blue[500],
};

export const onGetProfCoursesList = () => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getProfCourses();
      if (status === 200) {
        dispatch(fetchSuccess());
        let courses: CourseObj[];

        courses = data.courses.map((course) => ({
          id: course.id,
          title: course.c_title,
          summary: course.summary,
          label:
            course.category === 'ME'
              ? [MeLabel]
              : course.category === 'TH'
              ? [ThLabel]
              : [],
          events: [],
          tests: [],
        }));
        dispatch({
          type: GET_COURSE_LIST,
          payload: {list: courses, total: courses.length},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetAllCoursesList = () => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getAllCourses();
      if (status === 200) {
        dispatch(fetchSuccess());
        let courses: CourseObj[];

        courses = data.courses.map((course) => ({
          id: course.id,
          title: course.c_title,
          summary: course.summary,
          label:
            course.category === 'ME'
              ? [MeLabel]
              : course.category === 'TH'
              ? [ThLabel]
              : [],
          events: [],
          tests: [],
        }));
        dispatch({
          type: GET_COURSE_LIST,
          payload: {list: courses, total: courses.length},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateCourse = (createCourseDTO: CreateCourseDTO) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const courseLabel = {
        id: createCourseDTO.category === 'ME' ? 1 : 2,
        name:
          createCourseDTO.category === 'ME'
            ? 'Mechanical Engineering'
            : 'Thermal Engineering',
        color: createCourseDTO.category === 'ME' ? green[500] : blue[500],
      };
      const {data, status} = await createCourse(createCourseDTO);

      if (status === 201) {
        dispatch(fetchSuccess());
        dispatch({
          type: CREATE_NEW_COURSE,
          payload: {
            id: data.course.id,
            title: createCourseDTO.c_title,
            summary: createCourseDTO.summary,
            label: [courseLabel],
            events: [],
            tests: [],
          },
        });
        dispatch(showMessage(messages['course.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (error) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetSelectedCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseById(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        let course: CourseObj;
        course = {
          id: data.course.id,
          title: data.course.c_title,
          summary: data.course.summary,
          events: data.course.events.map((event) => {
            return {...event, lessons: []};
          }),
          label: [data.course.category === 'ME' ? MeLabel : ThLabel],
          tests: data.course.tests.map((test) => {
            return {
              id: test.id,
              name: test.name,
              summary: test.summary,
              type: test.is_postTest
                ? TestType.POST
                : test.is_preTest
                ? TestType.PRE
                : undefined,
              questions: test.questions
                ? test.questions.map((que) => {
                    return {...que, answer_value: 0};
                  })
                : [],
            };
          }),
        };
        dispatch({
          type: GET_COURSE_DETAIL,
          payload: course,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateLesson = (
  createLessonDTO: CreateLessonDTO,
  eventId: string,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await createLesson(createLessonDTO, eventId);

      if (status === 201) {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['lesson.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseLessonsList = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseLessons(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_LESSONS_LIST,
          payload: {courseId, lessons: data.lessons},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onEnrolCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await enrolCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        if (data.status === 'you enrolled the course successfully')
          dispatch(showMessage(messages['enrol.success'] as string));
        else if (data.status === 'you have already enrolled the course')
          dispatch(fetchError(messages['enrol.fail'] as string));
        else
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUnenrolCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await unenrolCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        if (data.status === "you've unenrolled the course successfully")
          dispatch(showMessage(messages['unenrol.success'] as string));
        else if (data.status === 'you are not enrolled in the course')
          dispatch(fetchError(messages['unenrol.fail'] as string));
        else
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseCompletionRate = (courseId: string) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCompletionRate(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_COMPLETION_RATE,
          payload: data.comp_rate,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onSendStudentAnswers = (answers: SendAnswersDTO, t_id: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await sendAnswers(answers, t_id);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['course.formSended'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseCompletionIncreaseRate = (courseId: string) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCompletionIncreaseRate(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_COMPLETION_RATE_INCREASE,
          payload: data.cir,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteCourse = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteCourse(courseId);
      if (status === 204) {
        dispatch(fetchSuccess());
        dispatch({
          type: DELETE_COURSE,
          payload: ~~courseId,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeletMultiCourses = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteCourse(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: DELETE_COURSE,
          payload: ~~courseId,
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetTestQuestions = (testId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getTestQuestions(testId);
      if (status === 200) {
        dispatch(fetchSuccess());
        let questions: QuestionObj[];
        questions = data.questions.map((que) => ({
          id: que.id,
          content: que.content,
          answer_type: que.answer_type,
          answer_value: 0,
        }));
        dispatch({type: GET_TEST_QUESTIONS, payload: {testId, questions}});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseTests = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseTests(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        let tests: TestObj[];
        tests = data.tests.map((test) => ({
          id: test.id,
          name: test.name,
          summary: test.summary,
          type: test.is_preTest ? 0 : 1,
          questions: [],
        }));
        dispatch({type: GET_COURSE_TESTS, payload: {courseId, tests}});
        tests.forEach(async (test) => {
          try {
            const {data, status} = await getTestQuestions(test.id);
            if (status === 200) {
              dispatch(fetchSuccess());
              let questions: QuestionObj[];
              questions = data.questions.map((que) => ({
                id: que.id,
                content: que.content,
                answer_type: que.answer_type,
                answer_value: 0,
              }));
              dispatch({
                type: GET_TEST_QUESTIONS,
                payload: {testId: test.id, questions},
              });
            } else {
              dispatch(
                fetchError(messages['message.somethingWentWrong'] as string),
              );
            }
          } catch (err) {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateTest = (
  courseId: number,
  testName: string,
  testSummary: string,
  questions: string[],
  answers: number[],
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status, data} = await createTest(courseId, {
        name: testName,
        summary: testSummary,
      });
      if (status === 201) {
        dispatch(fetchSuccess());
        let test: TestObj = {
          id: data.test.id,
          name: data.test.name,
          summary: data.test.summary,
          type: data.test.is_preTest ? 1 : data.test.is_postTest ? 0 : 0,
          questions: [],
        };
        questions.forEach(async (que, index) => {
          dispatch(fetchStart());
          try {
            const {status} = await createQuestion(test.id, {
              content: que,
              answer_type: answers[index],
            });
            if (status === 201) {
              dispatch(showMessage(messages['test.created'] as string));
            } else {
              dispatch(
                fetchError(messages['message.somethingWentWrong'] as string),
              );
            }
          } catch (err) {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        });
        dispatch(showMessage(messages['test.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetEventLessons = (eventId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getEventLessons(eventId);
      if (status === 200) {
        dispatch({
          type: GET_EVENT_LESSONS,
          payload: {eventId, lessons: data.lessons},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onCreateEvent = (
  createEventDto: CreateEventDTO,
  courseId: number,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await createEvent(courseId, createEventDto);
      if (status === 201) {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['event.created'] as string));
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetCourseEvents = (courseId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getCourseEvents(courseId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_COURSE_EVENTS,
          payload: {courseId, events: data.events},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onDeleteEvent = (eventId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status} = await deleteEvent(eventId);
      if (status === 204) {
        dispatch(fetchSuccess());
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onUpdateCourseDetail = (
  courseId: number,
  newCourse: CreateCourseDTO,
) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await updateCourse(courseId, newCourse);
      if (status === 200) {
        dispatch(fetchSuccess());
        const course: UpdatedCourseObj = {
          id: courseId,
          title: data.course.c_title,
          summary: data.course.summary,
          label:
            data.course.category === 'ME'
              ? [MeLabel]
              : data.course.category === 'TH'
              ? [ThLabel]
              : [],
        };
        dispatch({type: UPDATE_COURSE_DETAIL, payload: course});
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

export const onGetTestResults = (testId: number) => {
  const {messages} = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data, status} = await getTestResults(testId);
      if (status === 200) {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_TEST_RESULTS,
          payload: {answers: data.results.map((res) => +res.answer), testId},
        });
      } else {
        dispatch(fetchError(messages['message.somethingWentWrong'] as string));
      }
    } catch (err) {
      dispatch(fetchError(messages['message.somethingWentWrong'] as string));
    }
  };
};

// export const onGetPreTest = (testId: number, selectedCourse: CourseObj) => {
//   const {messages} = appIntl();
//   const history = useHistory();
//   return async (dispatch: Dispatch<AppActions>) => {
//     dispatch(fetchStart());
//     const postTest = selectedCourse.tests.find(
//       (test) => test.type === TestType.POST,
//     );
//     try {
//       if (postTest) {
//         const {data, status} = await getTestResults(postTest.id);
//         if (status === 200) {
//           dispatch(fetchSuccess());
//           if (data.results.length === 0)
//             history.push(
//               `/courses/course-detail/${selectedCourse.id}/test/${testId}`,
//             );
//         }
//       } else {
//         dispatch(fetchError(messages['message.somethingWentWrong'] as string));
//       }
//     } catch (err) {
//       dispatch(fetchError(messages['message.somethingWentWrong'] as string));
//     }
//   };
// };
