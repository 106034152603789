import {
  CompletionRateIncrease,
  CourseObj,
  EventObj,
  LessonObj,
  QuestionObj,
  TestObj,
  UpdatedCourseObj,
} from 'types/models/apps/Course';

export const CREATE_NEW_COURSE = 'CREATE_NEW_COURSE';
export const CREATE_NEW_LESSON = 'CREATE_NEW_LESSON';
export const GET_COURSE_LIST = 'GET_COURSE_LIST';
export const GET_COURSE_LESSONS_LIST = 'GET_COURSE_LESSONS_LIST';
export const GET_COURSE_DETAIL = 'GET_COURSE_DETAIL';
export const GET_COURSE_COMPLETION_RATE = 'GET_COURSE_COMPLETION_RATE';
export const GET_COURSE_COMPLETION_RATE_INCREASE =
  'GET_COURSE_COMPLETION_RATE_INCREASE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const GET_TEST_QUESTIONS = 'GET_TEST_QUESTIONS';
export const GET_COURSE_TESTS = 'GET_COURSE_TESTS';
export const CREATE_NEW_TEST = 'CREATE_NEW_TEST';
export const CREATE_NEW_QUESTION = 'CREATE_NEW_QUESTION';
export const GET_EVENT_LESSONS = 'GET_EVENT_LESSONS';
export const GET_COURSE_EVENTS = 'GET_COURSE_EVENTS';
export const UPDATE_COURSE_DETAIL = 'UPDATE_COURSE_DETAIL';
export const GET_TEST_RESULTS = 'GET_TEST_RESULTS';

export interface CreateNewCourseActions {
  type: typeof CREATE_NEW_COURSE;
  payload: CourseObj;
}

export interface CreateNewLessonActions {
  type: typeof CREATE_NEW_LESSON;
  payload: {lesson: LessonObj; courseId: number};
}

export interface GetCourseListActions {
  type: typeof GET_COURSE_LIST;
  payload: {
    list: CourseObj[];
    total: number;
  };
}

export interface GetCourseLessonsListActions {
  type: typeof GET_COURSE_LESSONS_LIST;
  payload: {
    courseId: number;
    lessons: LessonObj[];
  };
}

export interface GetCourseDetailActions {
  type: typeof GET_COURSE_DETAIL;
  payload: CourseObj;
}

export interface GetCourseCompletionRateActions {
  type: typeof GET_COURSE_COMPLETION_RATE;
  payload: number;
}

export interface GetCourseCompletionRateIncreaseActions {
  type: typeof GET_COURSE_COMPLETION_RATE_INCREASE;
  payload: CompletionRateIncrease;
}

export interface DeleteCourseActions {
  type: typeof DELETE_COURSE;
  payload: number;
}

export interface GetTestQuestionsActions {
  type: typeof GET_TEST_QUESTIONS;
  payload: {testId: number; questions: QuestionObj[]};
}

export interface GetCourseTestsActions {
  type: typeof GET_COURSE_TESTS;
  payload: {courseId: number; tests: TestObj[]};
}

export interface CreateNewTestActions {
  type: typeof CREATE_NEW_TEST;
  payload: TestObj;
}

export interface CreateNewQuestionActions {
  type: typeof CREATE_NEW_QUESTION;
  payload: {testId: number; question: QuestionObj};
}

export interface GetEventLessonsActions {
  type: typeof GET_EVENT_LESSONS;
  payload: {eventId: number; lessons: LessonObj[]};
}

export interface GetCourseEventsActions {
  type: typeof GET_COURSE_EVENTS;
  payload: {courseId: number; events: EventObj[]};
}

export interface UpdateCourseDetailActions {
  type: typeof UPDATE_COURSE_DETAIL;
  payload: UpdatedCourseObj;
}

export interface GetTestResultsActions {
  type: typeof GET_TEST_RESULTS;
  payload: {answers: number[]; testId: number};
}

export type CourseActions =
  | CreateNewCourseActions
  | CreateNewLessonActions
  | GetCourseListActions
  | GetCourseLessonsListActions
  | GetCourseDetailActions
  | GetCourseCompletionRateActions
  | GetCourseCompletionRateIncreaseActions
  | DeleteCourseActions
  | GetCourseTestsActions
  | CreateNewTestActions
  | CreateNewQuestionActions
  | GetTestQuestionsActions
  | GetEventLessonsActions
  | GetCourseEventsActions
  | UpdateCourseDetailActions
  | GetTestResultsActions;
