import {
  CourseActions,
  CREATE_NEW_COURSE,
  CREATE_NEW_QUESTION,
  CREATE_NEW_TEST,
  DELETE_COURSE,
  GET_COURSE_COMPLETION_RATE,
  GET_COURSE_COMPLETION_RATE_INCREASE,
  GET_COURSE_DETAIL,
  GET_COURSE_EVENTS,
  GET_COURSE_LESSONS_LIST,
  GET_COURSE_LIST,
  GET_COURSE_TESTS,
  GET_EVENT_LESSONS,
  GET_TEST_QUESTIONS,
  GET_TEST_RESULTS,
  UPDATE_COURSE_DETAIL,
} from 'types/actions/Course.action';
import {CourseObj} from 'types/models/apps/Course';

const initialState: {
  coursesList: CourseObj[];
  totalCourses: number;
  selectedCourse: CourseObj | null;
} = {
  coursesList: [],
  totalCourses: 0,
  selectedCourse: null,
};

const Courses = (state = initialState, action: CourseActions) => {
  switch (action.type) {
    case GET_COURSE_LIST:
      return {
        ...state,
        coursesList: action.payload.list,
        totalCourses: action.payload.total,
      };

    case CREATE_NEW_COURSE:
      return {
        ...state,
        coursesList: [...state.coursesList, action.payload],
        totalCourses: state.totalCourses + 1,
      };
    case GET_COURSE_LESSONS_LIST:
      return {
        ...state,
        coursesList: state.coursesList.map((course) => {
          var temp = Object.assign({}, course);
          // if (temp.id === action.payload.courseId)
          //   temp.lessons = action.payload.lessons;
          return temp;
        }),
      };
    case GET_EVENT_LESSONS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: state.selectedCourse?.events.map((event) =>
            event.id === action.payload.eventId
              ? {...event, lessons: action.payload.lessons}
              : event,
          ),
        },
      };
    case GET_COURSE_DETAIL:
      return {...state, selectedCourse: action.payload};

    case GET_COURSE_COMPLETION_RATE:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          completionRate: action.payload,
        },
      };

    case GET_COURSE_COMPLETION_RATE_INCREASE:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          completionRateIncrease: action.payload,
        },
      };

    case DELETE_COURSE:
      return {
        ...state,
        coursesList: state.coursesList.filter(
          (course) => course.id !== action.payload,
        ),
      };

    case GET_TEST_QUESTIONS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests?.map((test) =>
            test.id === action.payload.testId
              ? (test = {...test, questions: action.payload.questions})
              : test,
          ),
        },
      };

    case GET_COURSE_TESTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: action.payload.tests,
        },
      };

    case CREATE_NEW_TEST:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.concat(action.payload),
        },
      };

    case CREATE_NEW_QUESTION:
      return {
        ...state,
        selectedCourse: {
          tests: state.selectedCourse?.tests?.map((test) =>
            test.id === action.payload.testId
              ? (test = {
                  ...test,
                  questions: test.questions?.concat(action.payload.question),
                })
              : test,
          ),
        },
      };

    case GET_COURSE_EVENTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          events: action.payload.events,
        },
      };

    case UPDATE_COURSE_DETAIL:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          ...action.payload,
        },
      };

    case GET_TEST_RESULTS:
      return {
        ...state,
        selectedCourse: {
          ...state.selectedCourse,
          tests: state.selectedCourse?.tests.map((test) =>
            test.id === action.payload.testId
              ? (test = {
                  ...test,
                  questions: test.questions.map((que, index) => ({
                    ...que,
                    answer_value: action.payload.answers[index],
                  })),
                })
              : test,
          ),
        },
      };

    default:
      return state;
  }
};
export default Courses;
