import {makeStyles} from '@material-ui/core';
import {CremaTheme} from 'types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  customizerOption: {
    position: 'absolute',
    right: '10px',
    bottom: '38px',
    zIndex: 2275,

  },
  chatBotBox:{
    position: 'absolute',
    zIndex: 555,
    right: '20px',
    bottom: '100px',
    height:'calc(100% - 120px)',
    width:'376px',
    minHeight:'250px',
    maxHeight:'704px',
    boxShadow:'rgba(0, 0, 0, 0.16) 0px 5px 40px',
    // background: "linear-gradient(188deg ,#009aff, transparent )",
    // background: "radial-gradient(#0083ff, #00daff)",
    background:"white",
    opacity: 1,
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'width 350ms ease 0s, height ease 0s, max-height ease 0s',

  },
  textStyle: {
    color: 'white',
    padding: '11px 16px',

  },
  iconStyle:{
    width: '29px',
    height: '41px'
  },
  chatIconAnimationOpen:{
    WebkitAnimation:'halfSpin 1s linear   forwards',
    MozAnimation:'halfSpin 1s linear  forwards',
    animation:'halfSpin 1s linear  forwards',
  },
  chatIconAnimationClose:{
    WebkitAnimation:'spin .5s linear 1',
    MozAnimation:'spin .5s linear 1',
    animation:'spin .5s linear  1',
  },
  chatBoxAnimationOpen:{
    WebkitAnimation:'spin 1s linear   forwards',
    MozAnimation:'spin 1s linear  forwards',
    animation:'spin 1s linear  forwards',
  },
  chatBoxAnimationClose:{
    WebkitAnimation:'spin 2s linear 1   ',
    MozAnimation:'spin 2s linear 1  ',
    animation:'spin 2s linear  1  ',
  },
  customizerButton: {
    width: '60px',
    height: '60px',
    color: '#fff',
    borderRadius: '57px',
    backgroundColor: '#07071f',
    // backgroundColor: '#3047ec',
    '&:hover': {
      backgroundColor: '#26A1A6',
    },
    '& button': {
      borderRadius: '30px 0 0 30px',

      '&:focus': {
        borderRadius: '30px 0 0 30px',
      },
    },
  },
  rightSidebar: {
    width: 300,
    [theme.breakpoints.up('xl')]: {
      width: 400,
    },
  },
  rightSidebarHeader: {
    padding: '20px',
    borderBottom: '1px solid #e0e0e0',
    [theme.breakpoints.up('xl')]: {
      padding: '28px 22px',
    },
  },
  rightSidebarMain: {
    padding: '20px',
    [theme.breakpoints.up('xl')]: {
      padding: '28px 22px',
    },
  },
  customizerItem: {
    '&:not(:last-child)': {
      borderBottom: ['1px solid #e0e0e0'],
      paddingBottom: 20,
      marginBottom: 20,
      [theme.breakpoints.up('xl')]: {
        paddingBottom: 30,
        marginBottom: 30,
      },
    },
  },
  colorRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    color: 'white',
  },
  navOption: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '-10px',
    marginRight: '-10px',
  },
  navOptionItem: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 20,
  },
  navOptionContent: {
    position: 'relative',
    cursor: 'pointer',
  },
  navOptionRightIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    color: '',
  },
  selectBox: {
    '& .MuiOutlinedInput-input': {
      padding: '12px 32px 12px 14px',
    },
  },
  toggleBtn: {
    height: 36,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:not(:first-child)': {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('xl')]: {
      height: 48,
      minWidth: 96,
    },
    '&:hover,&:focus': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover,&:focus': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  colorOptionList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -5px',
    padding: 0,
    listStyle: 'none',
    '& > li': {
      padding: '0 5px',
      marginBottom: 10,
    },
  },
  wFull: {
    width: '100%',
  },
  textWhite: {
    color: 'white',
  },
  mb5: {
    marginBottom: 20,
  },
  hidden:{
    display:'none'
  },
  show:{
    display:'inline'
  }
}));
export default useStyles;
