import {SOCKET_URL} from './settings';
class WebSocketService {
  static dispatchWS: any;
  static instance: any = null;
  callbacks: any = {
    messages: {},
    new_message: {},
    course_info: {},
  };
  socketRef: any;
  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(chatUrl: any) {
    if (this.socketRef != null) return;
    const path = `${SOCKET_URL}/ws/chat/${chatUrl}/`;
    this.socketRef = new WebSocket(path);
    this.socketRef.onopen = () => {
      console.log('WebSocket open');
    };
    this.socketRef.onmessage = (e: {data: any}) => {
      this.socketNewMessage(e.data);
    };
    this.socketRef.onerror = (e: {message: any}) => {
      console.log(e.message);
    };
    this.socketRef.onclose = (code: any, reason: any) => {
      console.log("WebSocket closed let's reopen", code, reason);
      this.connect(chatUrl);
    };
  }

  disconnect() {
    this.socketRef.close();
  }

  socketNewMessage(data: any) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === 'messages') {
      this.callbacks[command](parsedData.messages);
    }
    if (command === 'new_message') {
      this.callbacks[command](parsedData.message);
    }
    if (command === 'course_info') {
      this.callbacks[command](parsedData.message);
    }
  }

  fetchMessages(username: string, chatId: number) {
    this.sendMessage({
      command: 'fetch_messages',
      username: username,
      chatId: chatId,
    });
  }

  newChatMessage(message: any) {
    this.sendMessage({
      command: 'new_message',
      from: message.from,
      message: message.content,
      chatId: message.chatId,
    });
  }

  getCousreInfo(message: any) {
    this.sendMessage({
      command: 'course_info',
      from: message.from,
      message: message.courseCategory,
      chatId: message.chatId,
    });
  }

  addCallbacks(
    messagesCallback: any,
    newMessageCallback: any,
    courseInfoCallback: any,
  ) {
    this.callbacks['messages'] = messagesCallback;
    this.callbacks['new_message'] = newMessageCallback;
    this.callbacks['course_info'] = courseInfoCallback;
  }

  sendMessage(data: any) {
    try {
      this.socketRef.send(JSON.stringify({...data}));
    } catch (err: any) {
      console.log(err.message);
    }
  }

  state() {
    return this.socketRef?.readyState;
  }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
