import {
  ADD_NEW_MESSAGE,
  CHANGE_DIGITAL_ENG_WINDOW,
  ChatActions,
  DELETE_MESSAGE,
  DELETE_USER_MESSAGES,
  EDIT_MESSAGE,
  GET_CONNECTIONS_LIST,
  GET_USER_MESSAGES,
  SELECT_USER,
  SET_CHAT_ID,
  SET_DIGITAL_ENG_CHAT,
  SET_LAST_MESSAGE,
  TOGGLE_CHAT_DRAWER,
} from '../../types/actions/Chat.actions';
import {ConnectionObj, MessageObj} from '../../types/models/apps/Chat';

const initialState: {
  connectionList: ConnectionObj[];
  chatDrawer: boolean;
  userMessages: MessageObj | null;
  selectedUser: ConnectionObj | null;
  digitalEngWindowIsOpened: boolean;
  digitalEngChat: ConnectionObj | null;
} = {
  connectionList: [],
  chatDrawer: false,
  userMessages: null,
  selectedUser: null,
  digitalEngWindowIsOpened: false,
  digitalEngChat: null,
};

const ChatApp = (state = initialState, action: ChatActions) => {
  switch (action.type) {
    case SET_CHAT_ID:
      return {
        ...state,
        CurrenChatId: action.payload,
      };
    case GET_CONNECTIONS_LIST:
      return {
        ...state,
        connectionList: action.payload,
        // selectedUser: action.payload.find((item) => item.name === 'digit'),
      };

    case TOGGLE_CHAT_DRAWER:
      return {
        ...state,
        chatDrawer: !state.chatDrawer,
      };

    case GET_USER_MESSAGES:
      return {
        ...state,
        userMessages: action.payload,
      };

    case ADD_NEW_MESSAGE: {
      return {
        ...state,
        // connectionList: state.connectionList.map((item) =>
        //   item.id === action.payload.data.user.id
        //     ? action.payload.data.user
        //     : item,
        // ),

        userMessages: {
          channelId: action.payload.data.userMessages.channelId,
          messageData: state.userMessages?.messageData.concat(
            action.payload.data.userMessages.messageData,
          ),
        },
      };
    }

    case SET_LAST_MESSAGE: {
      return {
        ...state,
        connectionList: state.connectionList.map((item) =>
          item.id === action.payload.user.id
            ? {
                ...item,
                lastMessage: {
                  id: action.payload.message.id ? action.payload.message.id : 0,
                  message: action.payload.message.message + '',
                  type: 'unread',
                  time: action.payload.message.time,
                },
              }
            : item,
        ),
      };
    }

    case EDIT_MESSAGE: {
      return {
        ...state,
        connectionList: state.connectionList.map((item) =>
          item.id === action.payload.data.user.id
            ? action.payload.data.user
            : item,
        ),
        userMessages: action.payload.data.userMessages,
      };
    }

    case DELETE_MESSAGE: {
      return {
        ...state,
        connectionList: state.connectionList.map((item) =>
          item.id === action.payload.user.id ? action.payload.user : item,
        ),
        userMessages: action.payload.userMessages,
      };
    }

    case DELETE_USER_MESSAGES: {
      return {
        ...state,
        connectionList: state.connectionList.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        ),
        userMessages: null,
        // selectedUser: null,
      };
    }

    case SELECT_USER: {
      return {
        ...state,
        selectedUser: action.payload,
        // userMessages: null,
      };
    }

    case CHANGE_DIGITAL_ENG_WINDOW: {
      return {
        ...state,
        digitalEngWindowIsOpened: action.payload,
      };
    }

    case SET_DIGITAL_ENG_CHAT: {
      return {
        ...state,
        digitalEngChat: action.payload,
      };
    }

    default:
      return state;
  }
};
export default ChatApp;
