import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {
  RegisterDTO,
  RegisterResponse,
  UserInfoResponse,
} from 'types/models/apps/Auth';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getUserInfo = (): Promise<UserInfoResponse> => {
  return jwtAxios.get('/users/info');
};

export const register = (
  registerDTO: RegisterDTO,
  roll: string,
): Promise<RegisterResponse> => {
  if (roll === 'student')
    return jwtAxios.post('/api/registration/student/', registerDTO);
  else return jwtAxios.post('/api/registration/teacher/', registerDTO);
};

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
  role: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const registerDTO: RegisterDTO = {
        email: body.email,
        password1: body.password,
        password2: body.password,
        username: body.name,
      };
      const {data} = await register(registerDTO, body.role);
      localStorage.setItem('token', data.key);
      localStorage.setItem('username', body.name);
      dispatch(setJWTToken(data.key));
      await loadJWTUser(dispatch, body.role);
    } catch (err: any) {
      if (err.response.status === 400)
        dispatch(fetchError('Error, email or user name already exsits'));
      else dispatch(fetchError('Error '));
    }
  };
};

export const onJwtSignIn = (body: {username: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {data} = await jwtAxios.post('/rest-auth/login/', body);
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('id', data.id);
      dispatch(setJWTToken(data.token));
      await loadJWTUser(dispatch, data.role);
    } catch (err: any) {
      if (err.response.status === 403)
        dispatch(
          fetchError(
            'Account locked for 24 hours: too many login attempts. Contact an admin to unlock your account.',
          ),
        );
      else if (err.response.status === 404)
        dispatch(fetchError('Wrong username or password'));
      else dispatch(fetchError('Error '));
    }
  };
};

export const loadJWTUser = async (
  dispatch: Dispatch<AppActions>,
  role: string,
) => {
  dispatch(fetchStart());
  try {
    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject({
        displayName: localStorage.getItem('username'),
        email: localStorage.getItem('username'),
        role: ['user', role],
        token: localStorage.getItem('token'),
        uid: 2,
        _id: 2,
        photoURL: 'images/avatar/A24.jpg',
      }),
    });
  } catch (err) {
    dispatch(fetchError(JSON.stringify(err)));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  return {
    authType: AuthType.JWT_AUTH,
    displayName: authUser.name,
    email: authUser.email,
    role: authUser.role,
    token: authUser._id,
    uid: authUser._id,
    photoURL: authUser.avatar,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('ChatId');
      localStorage.removeItem('id');
    }, 500);
  };
};

export const onGetUserInfo = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const {status, data} = await getUserInfo();
      if (status === 200) await loadJWTUser(dispatch, data.user_info.role);
      else dispatch(fetchError('Error '));
    } catch (err: any) {
      dispatch(fetchError('Error '));
    }
  };
};
